import Axios from 'axios';
import persist from './persist';
import Permissions from './permissions';


const getDefaultState = () => {
    return {
        name: '',
        login: null,
        authenticated: false,
        permissions: [],
        token: '',
        avatar: '',
        user_id: null,
        synced: true,
        acceptedDocuments: [],
        allAccepted: false,
        fcmToken: '123default',
    };
};
const p = Permissions;

const userStore = {
    state() {
        return {
            name: '',
            login: null,
            authenticated: false,
            permissions: [],
            token: '',
            avatar: '',
            user_id: null,
            acceptedDocuments: [],
            allAccepted: false,
            fcmToken: '456initial',
        };
    },
    mutations: {
        setAuthenticated: (state, token) => {
            state.token = token;
            state.authenticated = true;
            Axios.defaults.headers.common['Authorization'] = `${token}`;
        },
        setFcmToken: (state, fcmToken) => {
            state.fcmToken = fcmToken;
            console.log('Token Store mutations:', fcmToken);
        },
        setUser: (state, user) => {
            if (user !== null) {
                state.login = user.login;
                state.first_name = user.first_name;
                state.last_name = user.last_name;
                state.email = user.email;
                state.permissions = user.permissions;
                state.avatar = user.avatar;
                state.user_id = user.user_id;
                if (user.acceptedDocuments !== undefined) {
                    state.acceptedDocuments = user.acceptedDocuments;
                }
                if (user.allAccepted !== undefined) {
                    state.allAccepted = user.allAccepted;
                }
                state.fcmToken = user.fcmToken;
            }
        },
        resetUser: (state) => {
            Object.assign(state, getDefaultState());
        },
        persistUserState: (state) => {
            persist.set('user', JSON.parse(JSON.stringify(state)));
        },
        resetUserUpdatedState: (state) => {
            state.synced = true;
        },
        acceptDocument(state, doc_type) {
            if (!state.acceptedDocuments.includes(doc_type)) {
                state.acceptedDocuments.push(doc_type);
            }
            if (state.acceptedDocuments.length == 3) {
                state.allAccepted = true;
            }
        },
    },
    actions: {
        login({commit}, {token, user, fcmToken}) {
            commit('setAuthenticated', token);
            commit('setUser', user);
            commit('setFcmToken', fcmToken);
            console.log('Token Store actions:', fcmToken);
            commit('persistUserState');
        },
        logout: ({commit}) => {
            commit('resetUser', '');
            commit('persistUserState');
        },
        async initUserStore({commit}) {
            const user = await persist.get('user');
            commit('setUser', user);
            if (user !== null && user.token !== undefined) {
                commit('setAuthenticated', user.token);
            }
        },
        resetUserUpdatedState: () => {
            console.log('Nothing to do for user');
        },
        setUserState({commit}, user_data) {
            commit('setUser', user_data);
        },
        acceptDocument({commit}, doc_type) {
            commit('acceptDocument', doc_type);
            commit('persistUserState');
        },
        /* setFcmToken({commit}, fcmToken) {
            commit('setFcmToken', fcmToken);
            commit('persistUserState');
            console.log('Token Store:', fcmToken);
        },*/
    },
    getters: {
        authenticated(state) {
            return state.authenticated;
        },
        username(state) {
            return state.first_name;
        },
        fullUsername(state) {
            return `${state.first_name} ${state.last_name}`;
        },
        userPermissions(state) {
            return state.permissions;
        },
        isPatient(state) {
            return state.permissions.includes(p.constants.PERMISSIONS.PATIENT);
        },
        avatar: (state) => (user_id) => {
            if (user_id == state.user_id) {
                if (state.avatar === null) {
                    return 'avatar.png';
                } else {
                    return state.avatar;
                }
            } else {
                console.log('is avatar of colleague');
            }
        },
        user_id: (state) => {
            return state.user_id;
        },
        updatedUser(state) {
            return state;
        },
        currentUser(state) {
            return state;
        },
        token(state) {
            return state.token;
        },
        fcmToken(state) {
            console.log('Token Store getters:', state.fcmToken);
            return state.fcmToken;
        },
        documentAccepted: (state) => (doc_type) => {
            return state.acceptedDocuments.includes(doc_type);
        },
        allDocumentsAccepted(state) {
            return state.allAccepted;
        },
        usageAllowed(state) {
            return state.allAccepted;
        },
    },
};

export default userStore;
