export default {
    data() {
        return {
            PERMISSIONS: {
                DEVELOPER: -1,
                PATIENT: 95,
                EMPLOYEE: 96,
                ACCOUNTING: 97,
                OWNER: 98,
                ADMINISTRATION: 99,
            },
        };
    },
    constants: {
        PERMISSIONS: {
            DEVELOPER: -1,
            PATIENT: 95,
            EMPLOYEE: 96,
            ACCOUNTING: 97,
            OWNER: 98,
            ADMINISTRATION: 99,
        },
    },
    methods: {
        hasPermission(permission) {
            return this.$store.getters.userPermissions.includes(permission);
        },
    },
};
