<template>
    <div>
        <dashboard-card :title="'Therapiepläne'" :local_image="'therapie.jpg'">
            <div v-for="(workout, index) in filteredWorkouts" :key="index">
                <workout-entry :workout="workout" @click="startWorkout(workout.id)"></workout-entry>
            </div>
            <div style="justify-content: right; display: flex;">
                <ion-label @click="showWorkouts" color="primary">Alle anzeigen</ion-label>
            </div>
        </dashboard-card>
        <dashboard-card :title="'Termine'" :local_image="'calendar.jpg'">
            <div v-for="a in currentAppointments" v-bind:key="a.id">
                <appointment-entry :appointment="a"></appointment-entry>
            </div>
        </dashboard-card>
    </div>
</template>

<script>
import AppointmentEntry from '@/components/Appointments/AppointmentEntry.vue';
import DashboardCard from './DashboardCard.vue';
import WorkoutEntry from '@/components/Workout/WorkoutEntry.vue';
import {IonLabel} from '@ionic/vue';

export default {
    name: 'PatientDashboard',
    components: {
        DashboardCard,
        AppointmentEntry,
        WorkoutEntry,
        IonLabel,
    },
    props: [''],
    data() {
        return {
            visibleItems: 2, // Anzahl der anfänglich sichtbaren Einträge
        };
    },
    methods: {
        startWorkout(w_id) {
            this.$store.dispatch('setWorkoutLastExecuted', {workoutId: w_id, lastExecuted: new Date()});
            this.$router.push(`/workout/${w_id}`);
        },
        showWorkouts() {
            this.$router.push(`/workout_overview`);
        },
    },
    computed: {
        currentAppointments() {
            return this.$store.getters.nextAppointments(2);
        },
        currentWorkouts: function() {
            return this.$store.getters.currentWorkouts;
        },
        availableWorkouts: function() {
            return this.$store.getters.allWorkouts;
        },
        filteredWorkouts() {
            return this.availableWorkouts
                .slice(0, this.visibleItems)
                .sort((a, b) => b.lastExecuted - a.lastExecuted); // Sortiere nach lastExecuted absteigend
        },
    },
    mounted() {
    },
};
</script>
