import persist from './persist';

const RECIPE_STATE = {
    NEW: 0,
    RUNNING: 1,
    FINISHED: 2,
};

const getDefaultState = () => {
    return {
        recipes: [],
    };
};

const recipesStore = {
    state() {
        return {
            recipes: [],
        };
    },
    mutations: {
        resetRecipes: (state) => {
            Object.assign(state, getDefaultState());
        },
        persistRecipesState: (state) => {
            persist.set('recipes', JSON.parse(JSON.stringify(state)));
        },
        setRecipes: (state, recipes) => {
            if (recipes !== null && recipes.recipes !== undefined) {
                state.recipes = recipes.recipes;
                // eslint-disable-next-line guard-for-in
                for (const r in state.recipes) {
                    state.recipes[r].date = new Date(state.recipes[r].date);
                }
            }
        },
    },
    actions: {
        async initRecipeStore({commit}) {
            const recipes = await persist.get('recipes');
            commit('setRecipes', recipes);
        },
        setRecipeState({commit}, recipes) {
            commit('setRecipes', recipes);
        },
    },
    getters: {
        allRecipes(state) {
            return state.recipes;
        },
        openRecipes(state) {
            const recipes = state.recipes.filter((a) => {
                return (a.state == RECIPE_STATE.RUNNING);
            });
            return recipes;
        },
        newRecipes(state) {
            const recipes = state.recipes.filter((a) => {
                return (a.state == RECIPE_STATE.NEW);
            });
            return recipes;
        },
        closedRecipes(state) {
            const recipes = state.recipes.filter((a) => {
                return (a.state == RECIPE_STATE.FINISHED);
            });
            return recipes;
        },
        recipeById: (state) => (recipe_id) => {
            return state.recipes.find((recipe) => recipe.id === recipe_id);
        },
    },
};

export default recipesStore;
