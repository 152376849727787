import {createStore} from 'vuex';
import sync from './synch';
import imagestore from './imageStore';
import timestore from './timeStore';
import userstore from './userStore';
import colleaguestore from './colleagueStore';
import patientstore from './patientStore';
import recipestore from './recipeStore';
import appointmentstore from './appointmentStore';
import workoutStore from './workoutStore';
import events from './events';

const store = createStore({
    modules: {
        imagestore,
        timestore,
        userstore,
        colleaguestore,
        patientstore,
        recipestore,
        appointmentstore,
        workoutStore,
        events,
    },
    state() {
        return {
            initialized: false,
            syncStatus: 0, // 0: 0k, 1: upsyncing, 2: downsyncing 3: error
        };
    },
    mutations: {
        loadState: (state) => {
            state.initialized = true;
        },
        async upsync(state, {images, user, time, appointments, videos, videopath}) {
            console.log('upsync commit user', JSON.stringify(user));
            state.syncStatus = 1;
            const updated = {
                images: images,
                user: user,
                time: time,
                appointments: appointments,
                videos: videos,
                videopath: videopath,

            };
            sync.upsync(updated).then((response) => {
                if (response.status == 1) { // Status OK
                    state.syncStatus = 0;
                    this.commit('resetUserUpdatedState');
                    this.commit('resetImagesUpdatedState');
                    this.commit('resetVideosUpdatedState');
                    this.commit('resetTimeUpdatedState');
                    this.commit('resetAppointmentUpdatedState');
                } else {
                    state.syncStatus = 3;
                }
            });
        },
        // eslint-disable-next-line max-len
        async downsync(state, {user, time, colleagues, patients, recipes, appointments}) {
            state.syncStatus = 2;
            const currentData = {
                user,
                time,
                colleagues,
                patients,
                recipes,
                appointments,
            };
            sync.downsync(currentData).then((response) => {
                if (response.status == 1) { // Status OK
                    state.syncStatus = 0;
                    this.dispatch('setUserState', response.user);
                    this.dispatch('setTimeState', response.time);
                    this.dispatch('setColleagueState', {colleagues: response.colleagues});
                    this.dispatch('setPatientState', {patients: response.patients});
                    this.dispatch('setRecipeState', {recipes: response.recipes});
                    // eslint-disable-next-line max-len
                    this.dispatch('setAppointmentsState', {appointments: response.appointments});
                    this.dispatch('setWorkoutsState', {workouts: response.workouts});
                } else {
                    state.syncStatus = 3;
                }
            });
        },
        // eslint-disable-next-line max-len
        async sync(state, {user, time, colleagues, patients, recipes, images, appointments}) {
            state.syncStatus = 1;
            sync.upsync({images, user, time, appointments}).then((response) => {
                if (response.status == 1) { // Status OK
                    state.syncStatus = 0;
                    this.commit('resetUserUpdatedState');
                    this.commit('resetImagesUpdatedState');
                    this.commit('resetTimeUpdatedState');
                    this.commit('resetAppointmentUpdatedState');
                    state.syncStatus = 2;
                    // eslint-disable-next-line max-len
                    sync.downsync({user, time, colleagues, patients, recipes, appointments}).then((response) => {
                        if (response.status == 1) { // Status OK
                            state.syncStatus = 0;
                            this.dispatch('setUserState', response.user);
                            this.dispatch('setTimeState', response.time);
                            // eslint-disable-next-line max-len
                            this.dispatch('setColleagueState', {colleagues: response.colleagues});
                            this.dispatch('setPatientState', {patients: response.patients});
                            this.dispatch('setRecipeState', {recipes: response.recipes});
                            // eslint-disable-next-line max-len
                            this.dispatch('setAppointmentsState', {appointments: response.appointments});
                            this.dispatch('setWorkoutsState', {workouts: response.workouts});
                        } else {
                            state.syncStatus = 3;
                        }
                    });
                } else {
                    state.syncStatus = 3;
                }
            });
        },
    },
    actions: {
        upsync: ({commit, rootGetters}) => {
            console.log('upsync');
            const images = rootGetters.updatedImages;
            const videos = rootGetters.updatedVideos;
            const videopath = rootGetters.videoPath;
            const user = rootGetters.updatedUser;
            const time = rootGetters.updatedTime;
            const appointments = rootGetters.updatedAppointments;
            console.log('upsync data', videopath);
            commit('upsync', {images, user, time, appointments, videos, videopath});
        },
        downsync: ({commit, rootGetters}) => {
            const user = rootGetters.currentUser;
            const time = rootGetters.currentTime;
            const colleagues = rootGetters.colleagues;
            const patients = rootGetters.patients;
            const recipes = rootGetters.allRecipes;
            const appointments = rootGetters.allAppointments;
            // eslint-disable-next-line max-len
            commit('downsync', {colleagues, user, time, patients, recipes, appointments});
        },
        sync: ({commit, rootGetters}) => {
            const images = rootGetters.updatedImages;
            const user = rootGetters.currentUser;
            const time = rootGetters.currentTime;
            const colleagues = rootGetters.colleagues;
            const patients = rootGetters.patients;
            const recipes = rootGetters.allRecipes;
            const appointments = rootGetters.allAppointments;
            // eslint-disable-next-line max-len
            console.log('sync aus store', JSON.stringify(user));
            commit('sync', {images, user, time, colleagues, patients, recipes, appointments});
        },
        async init({commit, rootGetters}) {
            await this.dispatch('initUserStore');
            await this.dispatch('initColleagueStore');
            await this.dispatch('initPatientStore');
            await this.dispatch('initRecipeStore');
            await this.dispatch('initWorkoutStore');
            await this.dispatch('initAppointmentsStore');
            await this.dispatch('initImageStore');
            await this.dispatch('initTimeStore');
            commit('loadState');
            const images = rootGetters.updatedImages;
            const user = rootGetters.currentUser;
            const time = rootGetters.currentTime;
            const colleagues = rootGetters.colleagues;
            const patients = rootGetters.patients;
            const recipes = rootGetters.allRecipes;
            const appointments = rootGetters.allAppointments;
            // eslint-disable-next-line max-len
            setTimeout(commit('sync', {images, user, time, colleagues, patients, recipes, appointments}), 3000);
        },
    },
    getters: {
        initialized: (state) => {
            return state.initialized;
        },
        syncStatus: (state) => {
            return state.syncStatus;
        },
        isSyncing: (state) => {
            return state.syncStatus != 0;
        },
    },
});

export default store;
