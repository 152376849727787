import persist from './persist';

const APPOINTMENT_STATES = {
    SCHEDULED: 1,
    PROPOSAL: 2,
    PROPOSAL_ACCEPTED: 3,
    PROPOSAL_DENIED: 4,
    SCHEDULED_CANCELLED: 5,
};

const getDefaultState = () => {
    return {
        appointments: [],
    };
};

const appointmentStore = {
    state() {
        return {
            appointments: [],
        };
    },
    mutations: {
        resetAppointments: (state) => {
            Object.assign(state, getDefaultState());
        },
        persistAppointmentsState: (state) => {
            persist.set('appointments', JSON.parse(JSON.stringify(state)));
        },
        setAppointments: (state, appointments) => {
            if (appointments !== null && appointments.appointments !== undefined) {
                state.appointments = appointments.appointments;
                // eslint-disable-next-line guard-for-in
                for (const a in state.appointments) {
                    state.appointments[a].dt = new Date(state.appointments[a].dt);
                }
            }
        },
        acceptAppointment: (state, appointment_id) => {
            for (const a in state.appointments) {
                if (state.appointments[a].uid == appointment_id) {
                    state.appointments[a].state = APPOINTMENT_STATES.PROPOSAL_ACCEPTED;
                    state.appointments[a].updated = true;
                }
            }
        },
        declineAppointment: (state, appointment_id) => {
            for (const a in state.appointments) {
                if (state.appointments[a].uid == appointment_id) {
                    if (state.appointments[a].state == APPOINTMENT_STATES.PROPOSAL) {
                        state.appointments[a].state = APPOINTMENT_STATES.PROPOSAL_DENIED;
                        state.appointments[a].updated = true;
                    }
                    if (state.appointments[a].state == APPOINTMENT_STATES.SCHEDULED) {
                        // eslint-disable-next-line max-len
                        state.appointments[a].state = APPOINTMENT_STATES.SCHEDULED_CANCELLED;
                        state.appointments[a].updated = true;
                    }
                }
            }
        },
        resetAppointmentUpdatedState(state) {
            // eslint-disable-next-line guard-for-in
            for (const a in state.appointments) {
                state.appointments[a].updated = false;
            }
        },
        appointmentInCalendar: (state, appointment_id) => {
            // eslint-disable-next-line max-len
            state.appointments.find((appointment) => appointment.uid === appointment_id).in_calendar = true;
        },
        allAppointmentsInCalendar(state) {
            for (const a in state.appointments) {
                if (state.appointments[a].state == APPOINTMENT_STATES.SCHEDULED) {
                    state.appointments[a].in_calendar = true;
                }
            }
        },
        appointmentRemoveCalendar: (state, appointment_id) => {
            // eslint-disable-next-line max-len
            state.appointments.find((appointment) => appointment.uid === appointment_id).in_calendar = false;
        },
    },
    actions: {
        async initAppointmentsStore({commit}) {
            const appointments = await persist.get('appointments');
            commit('setAppointments', appointments);
        },
        setAppointmentsState({commit}, appointments) {
            commit('setAppointments', appointments);
        },
        acceptAppointment({dispatch, commit}, appointment_id) {
            commit('acceptAppointment', appointment_id);
            dispatch('upsync');
        },
        declineAppointment({dispatch, commit}, appointment_id) {
            commit('declineAppointment', appointment_id);
            dispatch('upsync');
        },
        sendAppointmentToCalendar({commit}, appointment_id) {
            commit('appointmentInCalendar', appointment_id);
            console.log(commit, appointment_id);
            console.log('sending to calendar');
        },
        sendAllAppointmentsToCalendar({commit}) {
            commit('allAppointmentsInCalendar');
            console.log(commit);
            console.log('sending All to calendar');
        },
        removeAppointmentFromCalendar({commit}, appointment_id) {
            commit('appointmentRemoveCalendar', appointment_id);
            console.log(commit, appointment_id);
            console.log('removing from calendar');
        },

    },
    getters: {
        allAppointments(state) {
            return state.appointments;
        },
        nextAppointments: (state) => (number_of_appointments) => {
            const appointments = [];
            for (const a in state.appointments) {
                if (a < number_of_appointments) {
                    appointments.push(state.appointments[a]);
                } else {
                    break;
                }
            }
            return appointments;
        },
        updatedAppointments(state) {
            const updated = [];
            for (const a in state.appointments) {
                if (state.appointments[a].updated) {
                    updated.push(state.appointments[a]);
                }
            }
            return updated;
        },
        isAppointmentInCalendar: (state) => (appointment_id) => {
            // eslint-disable-next-line max-len
            return state.appointments.find((appointment) => appointment.uid === appointment_id).in_calendar;
        },
    },
};

export default appointmentStore;
