<template>
    <ion-img v-if="src !== undefined" :src="imgPath"></ion-img>
</template>

<script>
import {IonImg} from '@ionic/vue';

export default {
    props: ['src'],
    components: {
        IonImg,
    },
    data() {
        return {
        };
    },
    computed: {
        imgPath() {
            const img = this.$store.getters.image(this.src);
            if (img === undefined) {
                this.$store.dispatch('loadImage', {fileName: this.src});
            }
            return img;
        },
    },
    methods: {
    },
    mounted() {
        this.$store.dispatch('loadImage', {fileName: this.src});
    },
};
</script>

<style scoped>

</style>
