import {toastController} from '@ionic/vue';
import axios from 'axios';


export default {
    install: (app) => {
    // inject a globally available $translate() method
        app.config.globalProperties.$timestamp = () => {
            return new Date();
        };
        app.config.globalProperties.$uuid = () => {
            return ([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g, (c) =>
            // eslint-disable-next-line max-len
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
            );
        };

        app.config.globalProperties.$toast = async (message) => {
            const toast = await toastController
                .create({
                    message: message,
                    duration: 3000,
                });
            return toast.present();
        };

        app.config.globalProperties.$dataGetter = (endpoint, callback) => {
            axios.get(`${process.env.VUE_APP_ROOT_API}${endpoint}`).then((response) => {
                callback(response.data);
            }).catch((error) => {
                console.warn(error);
                app.config.globalProperties.$toast('Für diese Funktion ist eine Netzwerkverbindung notwendig');
            });
        };

        app.config.globalProperties.$syncCallbacks = {
            callbacks: {},
            register: function(callback, uid = null, execute_immediately = true) {
                if (execute_immediately) {
                    callback();
                }
                this.callbacks[uid] = callback;
            },
            executeCallbacks: function() {
                for (const callback in this.callbacks) {
                    this.callbacks[callback]();
                }
            },
            unregister: function() {
                this.callbacks = {};
            },
        };
    },
};
