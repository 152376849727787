<template>
    <ion-card>
        <div v-if="local_image !== undefined" class="article-image"
            :style="{ backgroundImage: 'url(' + require('@/assets/img/' + local_image) + ')' }"></div>
        <synced-image class="article-image" v-if="synced_image !== undefined && synced_image !== null"
            :src="synced_image"></synced-image>
        <ion-card-header>
            <ion-button v-if="has_delete" class="center-right" color="clear" @click.stop="$emit('delete')">
                <ion-icon :icon="closeCircleOutline" color="danger"></ion-icon>
            </ion-button>
            <ion-card-title v-if="title !== undefined">{{ title }}</ion-card-title>
        </ion-card-header>

        <ion-card-content>
            <slot />
        </ion-card-content>
    </ion-card>
</template>

<script>
import {IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonIcon, IonButton} from '@ionic/vue';
import SyncedImage from '@/components/image/SyncedImage.vue';
import {closeCircleOutline} from 'ionicons/icons';

export default {
    name: 'DashboardCard',
    components: {
        IonCard, IonCardHeader, IonCardTitle, IonCardContent, SyncedImage, IonIcon, IonButton,
    },
    props: ['title', 'local_image', 'synced_image', 'has_delete'],
    data() {
        return {
            closeCircleOutline,
        };
    },
    methods: {

    },
    computed: {

    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
ion-card {
    -webkit-box-shadow: 6px 6px 12px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 6px 6px 12px 3px rgba(0, 0, 0, 0.08);
    margin: 25px;
    border-radius: 8px;

    ion-card-header {
        padding-bottom: 8px;

        ion-card-title {
            font-weight: 500;
        }
    }

    .article-image {
        background-position: center;
        background-size: cover;
        height: 100px;
        object-fit: cover;
    }

    .author-info {
        display: flex;
        align-items: center;
        padding-top: 12px;

        ion-avatar {
            display: inline-block;
            height: 3em;
            width: 3em;
        }

        .author-name {
            color: var(--ion-color-light-contrast);
            padding-left: 12px;
        }

        .article-date {
            display: inline-block;
            margin-left: auto;
            opacity: 0.5;
        }
    }
}

ion-card-header {
    position: relative
}

.center-right {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
}</style>
