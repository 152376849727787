import {createApp} from 'vue';
import App from './App.vue';
import BaseLayout from './components/base/BaseLayout.vue';
import router from './router';
import store from './services/store';
import functions from './plugins/functions';
import Permissions from './services/permissions';
import {IonicVue} from '@ionic/vue';
import mitt from 'mitt';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Hallo */

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/theme.scss';
import './theme/utils.scss';

// set auth header
// eslint-disable-next-line max-len
// Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.user.token}`;

const emitter = mitt();

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(store)
    .use(functions);
app.mixin(Permissions);
app.component('base-layout', BaseLayout);
app.config.globalProperties.emitter = emitter;

router.beforeEach((to, from, next) => {
    app.config.globalProperties.$syncCallbacks.unregister();
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters.initialized) {
            store.dispatch('init').then(() => {
                if (!store.getters.authenticated) {
                    next({name: 'Login'}); // go to login
                } else if (store.getters.isPatient) {
                    if (!store.getters.allDocumentsAccepted) {
                        next({name: 'Dokumente'});
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            });
        } else {
            if (!store.getters.authenticated) {
                next({name: 'Login'}); // go to login
            } else {
                next();
            }
        }
    } else {
        next(); // go to wherever I'm going
    }
});

router.isReady().then(() => {
    app.mount('#app');
});
