import persist from './persist';


const getDefaultState = () => {
    return {
        patients: [],
    };
};

const patientsStore = {
    state() {
        return {
            patients: [],
        };
    },
    mutations: {
        resetPatients: (state) => {
            Object.assign(state, getDefaultState());
        },
        persistPatientsState: (state) => {
            persist.set('patients', JSON.parse(JSON.stringify(state)));
        },
        setPatients: (state, patients) => {
            if (patients !== null) {
                state.patients = patients.patients;
            }
        },
    },
    actions: {
        async initPatientStore({commit}) {
            const patients = await persist.get('patients');
            commit('setPatients', patients);
        },
        setPatientState({commit}, patients) {
            commit('setPatients', patients);
        },
    },
    getters: {
        patients(state) {
            return state.patients;
        },
        patientById: (state) => (user_id) => {
            return state.patients.find((user) => user.user_id === user_id);
        },
    },
};

export default patientsStore;
