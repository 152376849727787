<template>
    <dashboard-card :local_image="'therapistdash.jpg'">
        <ion-label>
            <h1 style="margin-bottom: 3%;">Meine Termine:</h1>
        </ion-label>
        <div v-for="appointment in therapistAppoitments" :key="appointment.id">
            <today-calender :appointment="appointment"></today-calender>
        </div>
        <div style="justify-content: right; display: flex;">
            <ion-label @click="showAppointments" color="primary">Alle anzeigen</ion-label>
        </div>
    </dashboard-card>
</template>

<script>
import DashboardCard from './DashboardCard.vue';
import {IonLabel} from '@ionic/vue';
import TodayCalender from '@/components/Appointments/TodayCalender.vue';
import axios from 'axios';

export default {
    components: {
        DashboardCard,
        TodayCalender,
        IonLabel,
    },
    name: 'EmployeeDashboard',
    data() {
        return {
            therapistAppoitments: [],
        };
    },
    methods: {
        async load() {
            axios
                .post(process.env.VUE_APP_ROOT_API + `gettherapistappointments/`, {dash: 'true'})
                .then((response) => {
                    this.therapistAppoitments = response.data;
                })
                .catch(() => {
                    this.$toast('Für diese Funktion ist eine Netzwerkverbindung notwendig');
                });
        },
        showAppointments() {
            this.$router.push(`/appointments`);
        },
    },
    computed: {

    },
    mounted() {
        this.load();
    },
};
</script>
