<template>
  <ion-row>
    <ion-col size="2">
      <ion-avatar class="avatar">
        <ion-icon size="large" :color="iconColor" :icon="iconType"></ion-icon>
      </ion-avatar>
    </ion-col>
    <ion-col>
      <div class="review-item">
        <h6>{{ workout.workout_title }}</h6>
        <p class="comment-text" v-if="workoutLastExecuted">
          Am {{ dateFormat(workoutLastExecuted) }} ausgeführt
        </p>
        <p class="comment-text" v-else>
          Wurde noch nicht ausgeführt
        </p>
      </div>
    </ion-col>
  </ion-row>
</template>

<script>
import {IonRow, IonCol, IonAvatar, IonIcon} from '@ionic/vue';
import {checkmarkCircle, playCircle} from 'ionicons/icons';
import moment from 'moment';

export default {
    name: 'WorkoutEntry',
    props: ['workout'],
    components: {
        IonRow,
        IonCol,
        IonAvatar,
        IonIcon,
    },
    computed: {
        iconColor() {
            return this.isWorkoutExpired ? 'secondary' : 'success';
        },
        iconType() {
            return this.isWorkoutExpired ? playCircle : checkmarkCircle;
        },
        workoutLastExecuted() {
            const workoutId = this.workout.id;
            return this.$store.getters.workoutLastExecutedById(workoutId);
        },
        isWorkoutExpired() {
            const lastExecuted = this.workoutLastExecuted;
            if (lastExecuted !== null) {
                const currentTime = moment();
                const lastExecutedTime = moment(lastExecuted);
                const diffInHours = currentTime.diff(lastExecutedTime, 'hours');
                return diffInHours > 24;
            }
            return true;
        },
    },
    methods: {
        dateFormat(date) {
            return moment(date).locale('de').format('DD.MM.YY');
        },
    },
};
</script>

<style scoped lang="scss">
.review-item {
  padding: 6px 16px;
  .comment-text {
    font-size: 14px;
    opacity: 0.5;
  }
}
.avatar {
  margin-top: 16px;
}
</style>
