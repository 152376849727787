<template>
    <ion-row>
        <ion-col size="3">
            <ion-avatar class="avatar">
                <synced-image :src="appointment.therapist.profile_img"/>
            </ion-avatar>
        </ion-col>
        <ion-col>
            <div class="review-item">
                <span class="review-date">{{ dateFormat(appointment.dt) }}</span>
                <h6>{{ appointment.description }}</h6>
                <p class="comment-text">{{ `${appointment.therapist.first_name} ${appointment.therapist.last_name}` }}</p>
            </div>
        </ion-col>
    </ion-row>
</template>

<script>
import moment from 'moment';
import {IonRow, IonCol, IonAvatar} from '@ionic/vue';
import SyncedImage from '../image/SyncedImage.vue';

export default {
    name: 'AppointmentEntry.vue',
    props: ['appointment'],
    components: {
        IonRow, IonCol, SyncedImage, IonAvatar,
    },
    data() {
        return {

        };
    },
    methods: {
        dateFormat(date) {
            return moment(date).locale('de').format('dddd DD.MM.YY | HH:mm');
        },
    },
    computed: {

    },
    mounted() {

    },
};
</script>

<style scoped lang="scss">
.review-item {
    padding: 6px 16px;

    .comment-text {
        font-size: 14px;
        opacity: 0.5;
    }

    raven-stars {
        display: inline-block;
        font-size: 14px;
    }

    .review-date {
        font-size: 14px;
        opacity: 0.5;
    }
}
.avatar {
    margin-top: 20px;
}
</style>
