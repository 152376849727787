import persist from './persist';
import moment from 'moment';
import sync from './synch';

const getDefaultState = () => {
    return {
        workouts: [],
        exercises: [],
        exerciseDurations: [],
        workoutLastExecuted: [],
        lastWorkout: null,
    };
};

const workoutStore = {
    state() {
        return {
            workouts: [],
            exercises: [],
            exerciseDurations: [],
            workoutLastExecuted: [],
            lastWorkout: null,
        };
    },
    mutations: {
        resetWorkouts: (state) => {
            Object.assign(state, getDefaultState());
        },
        persistWorkoutsState: (state) => {
            persist.set('workouts', JSON.parse(JSON.stringify(state)));
            console.log('persist', state);
        },
        setWorkouts: (state, workouts) => {
            if (workouts !== null && workouts.workouts !== undefined) {
                state.workouts = workouts.workouts;
            }
        },
        setExercises: (state, exercises) => {
            if (exercises !== null && exercises.exercises !== undefined) {
                state.exercises = exercises.exercises;
            }
        },
        initDurations: (state, durations) => {
            if (durations !== null && durations.exerciseDurations !== undefined) {
                state.exerciseDurations = durations.exerciseDurations;
            } else {
                state.exerciseDurations = [];
            }
        },
        setLastWorkouts: (state, workout) => {
            if (workout !== null && workout.lastWorkout !== undefined) {
                state.lastWorkout = workout.lastWorkout;
            }
            if (workout !== null && workout.workoutLastExecuted !== undefined) {
                state.workoutLastExecuted = workout.workoutLastExecuted;
            }
        },
        setWorkoutLastExecuted(state, {workoutId, lastExecuted}) {
            const workout = state.workoutLastExecuted.find((item) => item.workoutId === workoutId);
            if (workout) {
                workout.lastExecuted = lastExecuted;
            } else {
                state.workoutLastExecuted.push({workoutId, lastExecuted});
            }
        },
        newWorkout: (state, workout) => {
            state.workouts.push(workout);
            sync.downsync();
        },
        deleteWorkout: (state, index) => {
            const workout = state.workouts[index];
            sync.delete({workout: workout});
            state.workouts.splice(index, 1);
        },
        setExerciseDuration(state, {exerciseid: exerciseid, duration: duration}) {
            const exerciseIndex = state.exerciseDurations.findIndex((item) => item.exerciseid === exerciseid);
            if (exerciseIndex !== -1) {
                state.exerciseDurations[exerciseIndex].duration = duration;
            } else {
                state.exerciseDurations.push({exerciseid: exerciseid, duration: duration});
            }
        },
    },
    actions: {
        async initWorkoutStore({commit}) {
            const workouts = await persist.get('workouts');
            commit('setWorkouts', workouts);
            commit('setExercises', workouts);
            commit('setLastWorkouts', workouts);
            commit('initDurations', workouts);
        },
        setWorkoutsState({commit}, workouts) {
            commit('setWorkouts', workouts);
            commit('setExercises', workouts);
        },
        createNewWorkout({dispatch}, workout) {
            sync.create({workout: workout});
            dispatch('sync');
        },
        createNewExercise({dispatch}, exercise) {
            sync.create({exercise: exercise});
            dispatch('sync');
        },
        deleteWorkout({commit}, index) {
            commit('deleteWorkout', index);
            commit('persistWorkoutsState');
        },
        setLastWorkout({commit}, workout) {
            workout.date = moment().format('DD.MM.YY HH:mm');
            commit('setLastWorkout', {lastWorkout: workout});
            commit('persistWorkoutsState');
        },
        setWorkoutLastExecuted({commit}, {workoutId: workoutId, lastExecuted: lastExecuted}) {
            commit('setWorkoutLastExecuted', {workoutId: workoutId, lastExecuted: lastExecuted});
            commit('persistWorkoutsState');
        },
        setExerciseDuration({commit}, {exerciseid: exerciseid, duration: duration}) {
            commit('setExerciseDuration', {exerciseid: exerciseid, duration: duration});
            commit('persistWorkoutsState');
        },
    },
    getters: {
        allWorkouts(state) {
            return state.workouts;
        },
        allExercises(state) {
            return state.exercises;
        },
        workoutById: (state) => (workout_id) => {
            if (workout_id == -1) {
                return undefined;
            }
            return state.workouts.find((workout) => workout.id === workout_id);
        },
        exerciseById: (state) => (ex_id) => {
            return state.exercises.find((ex) => ex.id === ex_id);
        },
        myLastWorkout: (state) => {
            return state.lastWorkout;
        },
        currentWorkouts: () => {
            return [
                {
                    date: moment(new Date()),
                    name: 'Obere Exremität',
                    duration: 30,
                    image: 'avatar.png',
                    status: 1,
                    lastExecuted: moment(new Date()).subtract(1, 'week'),
                },
                {
                    date: moment(new Date()),
                    name: 'Untere Exremität',
                    duration: 30,
                    image: 'avatar.png',
                    status: 0,
                    lastExecuted: moment(new Date()).subtract(2, 'week'),
                },
            ];
        },
        exerciseDurationById: (state) => (exerciseid) => {
            const exerciseDuration = state.exerciseDurations.find(
                (item) => item.exerciseid === exerciseid,
            );
            return exerciseDuration ? exerciseDuration.duration : null;
        },
        workoutLastExecutedById: (state) => (workoutId) => {
            const workout = state.workoutLastExecuted.find((item) => item.workoutId === workoutId);
            return workout ? workout.lastExecuted : null;
        },
    },
};

export default workoutStore;
