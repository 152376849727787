<template>
    <div>
        <owner-dashboard v-if="hasPermission(PERMISSIONS.OWNER)"></owner-dashboard>
        <employee-dashboard v-if="hasPermission(PERMISSIONS.EMPLOYEE)"></employee-dashboard>
        <accounting-dashboard v-if="hasPermission(PERMISSIONS.ACCOUNTING)"></accounting-dashboard>
        <patient-dashboard v-if="hasPermission(PERMISSIONS.PATIENT)"></patient-dashboard>
    </div>
</template>

<script>
import OwnerDashboard from './DashboardElements/OwnerDashboard.vue';
import AccountingDashboard from './DashboardElements/AccountingDashboard.vue';
import EmployeeDashboard from './DashboardElements/EmployeeDashboard.vue';
import PatientDashboard from './DashboardElements/PatientDashboard.vue';

export default {
    components: {
        EmployeeDashboard,
        OwnerDashboard,
        PatientDashboard,
        AccountingDashboard,
    },
    name: 'UserDashboard',
    data() {
        return {

        };
    },
    methods: {

    },
    computed: {

    },
    mounted() {

    },
};
</script>
