<template>
    <div>
        <!--
            <ion-icon slot="start" v-if="syncStatus == 0" :icon="cloudOutline" color="success"></ion-icon>
        -->
        <ion-icon slot="start" v-if="syncStatus == 1" :icon="cloudUploadOutline" color="primary"></ion-icon>
        <ion-icon slot="start" v-if="syncStatus == 2" :icon="cloudDownloadOutline" color="primary"></ion-icon>
        <ion-icon slot="start" v-if="syncStatus == 3" :icon="cloudOffline" color="danger" @click="sync"></ion-icon>
    </div>
</template>

<script>
import {cloudUploadOutline, cloudOffline, cloudDownloadOutline} from 'ionicons/icons';
import {IonIcon} from '@ionic/vue';

export default {
    components: {
        IonIcon,
    },
    data() {
        return {
            cloudUploadOutline,
            cloudDownloadOutline,
            cloudOffline,
        };
    },
    computed: {
        syncStatus() {
            return this.$store.getters.syncStatus;
        },
    },
    methods: {
        sync() {
            this.$store.dispatch('upsync');
        },
    },
};
</script>

<style scoped>

</style>
