<template>
    <div>
        <ion-fab vertical="bottom" horizontal="center">
            <ion-fab-button color="success">
                <ion-icon :icon="checkmarkOutline" @click="save"></ion-icon>
            </ion-fab-button>
        </ion-fab>
    </div>
</template>

<script>
import {checkmarkOutline} from 'ionicons/icons';
import {IonIcon, IonFab, IonFabButton} from '@ionic/vue';

export default {
    components: {
        IonIcon,
        IonFab,
        IonFabButton,
    },
    data() {
        return {
            checkmarkOutline,
        };
    },
    computed: {
    },
    methods: {
        save() {
            this.$emit('save');
        },
    },
};
</script>

<style scoped>

</style>
