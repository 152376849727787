<template>
    <synced-component>
        <ion-label>
            <h1 style="margin-bottom: 3%;">Neue Rezepte: {{ countNewRecipes }}</h1>
        </ion-label>
        <ion-item button v-for="recipe in filteredRecipes" :key="recipe.id" lines="none" @click="recipeRegistration(recipe.id)">
          <ion-label>
            <h2>{{ recipe.user }}</h2>
            <p>{{ recipe.date }}</p>
          </ion-label>
        </ion-item>
        <ion-item v-if="showMoreButton" @click="showMoreItems" lines="none" color="primary" style="border-radius: 20px; margin-top: 3%">
            <ion-label>Alle anzeigen</ion-label>
            <recipe-icon slot="end"/>
        </ion-item>
    </synced-component>
</template>

<script>
import SyncedComponent from '../base/SyncedComponent.vue';
import {IonItem, IonLabel} from '@ionic/vue';
import RecipeIcon from './RecipeIcon.vue';

export default {
    name: 'RecipesEntry',
    provide() {
        return {
            load: this.load,
        };
    },
    components: {
        IonLabel,
        IonItem,
        RecipeIcon,
        SyncedComponent,
    },
    data() {
        return {
            newRecipes: [],
            visibleItems: 2, // Anzahl der anfänglich sichtbaren Einträge
        };
    },
    methods: {
        async load() {
            this.$dataGetter(`getnewrecipes/`, (data) => {
                this.newRecipes = data;
            });
        },
        shouldShowItem(index) {
            return index < this.visibleItems;
        },
        showMoreItems() {
            this.visibleItems = this.newRecipes.length; // Zeige alle restlichen Einträge an
        },
        recipeRegistration(r_id) {
            this.$router.push(`/recipes/registration/${r_id}`);
        },
    },
    computed: {
        filteredRecipes() {
            return this.newRecipes.slice(0, this.visibleItems);
        },
        showMoreButton() {
            return this.visibleItems < this.newRecipes.length;
        },
        countNewRecipes() {
            return this.newRecipes.length;
        },
    },
};
</script>

<style scoped lang="scss">
.button{
    display: flex;
    justify-content: center;
}
</style>
