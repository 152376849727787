<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'SyncedComponent',
    inject: ['load'],
    data() {
        return {
            observer: null,
            uid: this.$uuid(),
        };
    },
    methods: {
        initObserver() {
            const options = {
                root: null,
                threshold: 0.1,
            };

            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.$syncCallbacks.register(this.load, this.uid);
                    }
                });
            }, options);
            this.observer.observe(this.$el);
        },
    },
    mounted() {
        this.initObserver();
    },
};
</script>
