<template>
    <ion-item lines="none">
      <ion-label class="ion-text-wrap">
        <h2>
          <ion-text :color="isLatestAppointment(appointment) ? 'primary' : 'medium'">
            <b>{{ appointment.time }}</b>
          </ion-text> {{ appointment.patient }}
        </h2>
        <p>{{ appointment.description }}</p>
      </ion-label>
    </ion-item>
  </template>

<script>
import {IonItem, IonLabel, IonText} from '@ionic/vue';

export default {
    name: 'TodayCalender',
    props: ['appointment'],
    components: {
        IonLabel,
        IonItem,
        IonText,
    },
    methods: {
        isLatestAppointment(appointment) {
            const currentTime = new Date().setSeconds(0, 0);
            const [appointmentHour, appointmentMinute] = appointment.time.split(':');
            const appointmentTime = new Date().setHours(appointmentHour, appointmentMinute, 0, 0);
            return appointmentTime >= currentTime;
        },
    },
};
</script>

<style scoped lang="scss">
</style>
