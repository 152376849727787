<template>
    <div>
        <ion-fab vertical="bottom" horizontal="end">
            <ion-fab-button>
                <ion-icon :icon="arrowUpOutline"></ion-icon>
            </ion-fab-button>
        </ion-fab>
    </div>
</template>

<script>
import {arrowUpOutline} from 'ionicons/icons';
import {IonIcon, IonFab, IonFabButton} from '@ionic/vue';

export default {
    components: {
        IonIcon,
        IonFab,
        IonFabButton,
    },
    data() {
        return {
            arrowUpOutline,
        };
    },
    computed: {
    },
    methods: {
    },
};
</script>

<style scoped>

</style>
