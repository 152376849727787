<template>
    <ion-content>
        <ion-tabs>
            <ion-router-outlet />
            <ion-tab-bar slot="bottom" v-if="allAccepted">
                <ion-tab-button v-on:click="changeRoute('/home')">
                    <ion-icon :color="buttonColor('/home')" :icon="gridOutline"></ion-icon>
                </ion-tab-button>
                <ion-tab-button v-on:click="changeRoute('/workout_overview')">
                    <ion-icon :color="buttonColor('/workout_overview')" :icon="barbellOutline"></ion-icon>
                </ion-tab-button>
                <ion-tab-button v-on:click="changeRoute('/appointments')">
                    <ion-icon :color="buttonColor('/appointments')" :icon="calendarOutline"></ion-icon>
                </ion-tab-button>
                <ion-tab-button v-on:click="changeRoute('/settings')">
                    <ion-icon :color="buttonColor('/settings')" :icon="personCircleOutline"></ion-icon>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-content>
</template>

<script>
import {IonRouterOutlet, IonContent, IonTabBar, IonTabs, IonTabButton, IonIcon} from '@ionic/vue';
import {gridOutline, barbellOutline, personCircleOutline, calendarOutline} from 'ionicons/icons';

export default {
    name: 'BotNavigation',
    components: {
        IonContent, IonTabBar, IonTabs, IonRouterOutlet, IonTabButton, IonIcon,
    },
    data() {
        return {
            gridOutline, barbellOutline, personCircleOutline, calendarOutline,
        };
    },
    computed: {
        buttonColor() {
            return (route) => {
                if (this.$route.path.includes(route)) {
                    return 'secondary';
                } else {
                    return 'medium';
                }
            };
        },
        allAccepted() {
            return this.$store.getters.allDocumentsAccepted || !this.$store.getters.isPatient;
        },
    },
    methods: {
        changeRoute(r) {
            this.$router.push(r);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
