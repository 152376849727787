<template>
    <base-layout page-title="Übersicht" :isHome="true">
        <ion-row class="avatar_section">
            <ion-avatar class="avatar ion-margin">
                <synced-image :src="avatar" />
            </ion-avatar>
            <div class="hello_text">
                <p class="">
                    <ion-text color="light">
                        <h5>Hallo {{ username }}!</h5>
                    </ion-text>
                </p>
            </div>
        </ion-row>
        <user-dashboard />
    </base-layout>
</template>

<script>
import {IonText, IonAvatar, IonRow} from '@ionic/vue';
import SyncedImage from '../components/image/SyncedImage.vue';
import UserDashboard from '@/components/Home/UserDashboard.vue';

export default {
    components: {
        IonText,
        IonAvatar,
        IonRow,
        SyncedImage,
        UserDashboard,
    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
    },
    computed: {
        username() {
            return this.$store.getters.username;
        },
        avatar() {
            return this.$store.getters.avatar(this.$store.getters.user_id);
        },
    },
};
</script>

<style scoped>
.avatar_section {
    background: var(--ion-color-primary);
}

.hello_text {
    justify-content: center;
    display: flex !important;
    align-items: center !important;
}

.hello_text>p {
    margin: 0px;
}
</style>
