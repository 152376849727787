// src/services/AuthService.js
import axios from 'axios';
import imgsync from './imgsync';
import {Capacitor} from '@capacitor/core';

const url = process.env.VUE_APP_ROOT_API;

/**
 * @param {int} ms
 * @return {Promise}
 */
function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
    async upsync(data) {
        await timeout(500);
        return axios
            .post(url + 'sync/up/', data)
            .then(async function(response) {
                // eslint-disable-next-line guard-for-in
                for (const i in data.images) { // hier werden die bilder hochgeladen
                    await imgsync.upload(data.images[i].img, data.images[i].fileName);
                }
                // eslint-disable-next-line guard-for-in
                for (const i in data.videos) { // hier werden die videos hochgeladen!!!
                    await imgsync.uploadVideo(Capacitor.convertFileSrc(`${data.videopath}/${data.videos[i].fileName}`),
                        data.videos[i].fileName);
                }
                return response.data;
            }).catch((error) => {
                return {status: 3, message: error};
            });
    },
    async downsync(data) {
        return axios
            .post(url + 'sync/down/', data)
            .then(async function(response) {
                response.data.status = 1;
                return response.data;
            }).catch((error) => {
                return {status: 3, message: error};
            });
    },
    async create(data) {
        return axios
            .post(url + 'sync/create/', data)
            .then(async function(response) {
                response.data.status = 1;
                return response.data;
            }).catch((error) => {
                return {status: 3, message: error};
            });
    },
    async delete(data) {
        return axios
            .post(url + 'sync/delete/', data)
            .then(async function(response) {
                response.data.status = 1;
                return response.data;
            }).catch((error) => {
                return {status: 3, message: error};
            });
    },
    async getImage(filename) {
        return await imgsync.download(filename);
    },
    async getVideo(filename) {
        return await imgsync.downloadVideo(filename);
    },
};
