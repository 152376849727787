/**
 * @param {*} directory
 * @param {*} fileName
 * @return {Promise<boolean>} A promise that resolves with true if the file exists, false otherwise
 */
export function fileExists(directory, fileName) {
    return new Promise(async (resolve, reject) => {
        await timeout(500);
        const filePath = `${directory}/${fileName}`;

        window.resolveLocalFileSystemURL(filePath,
            function(fileEntry) {
                console.log('File exists: ' + fileEntry.name);
                // File exists
                resolve(true);
            },
            function(error) {
                console.log('File does not exist: ' + error);
                resolve(false);
            },
        );
    });
}

/**
 * @param {int} ms
 * @return {Promise}
 */
function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
