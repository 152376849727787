import {PushNotifications} from '@capacitor/push-notifications';

export default {
    async getPermission() {
        await PushNotifications.requestPermissions(),
        console.log('Push permissions granted.');
    },
    async getFcmToken() {
        await PushNotifications.register();
        const token = await new Promise((resolve) => {
            PushNotifications.addListener('registration', (token) => {
                console.log('Push Token:', token.value);
                console.log('Push Token Objekt:', token);
                resolve(token.value);
            });
        });
        console.log('Registered for push notifications.', token);
        return token;
    },

    async unregisterFcmToken() {
        try {
            // Entferne zuerst alle zuvor registrierten Event-Listener
            PushNotifications.removeAllListeners();
            console.log('Push Listeners removed.');

            // De-registriere dann die App von den Push-Benachrichtigungen
            await PushNotifications.unregister();
            console.log('Unregistered from push notifications.');
        } catch (error) {
            console.error('Error while unregistering from push notifications:', error);
        }
    },
};


// Hier sind die Beispiele, wie man die Push Notifications nutzen kann
/*
const addListeners = async () => {
    await PushNotifications.addListener('registration', (token) => {
        console.info('Registration token: ', token.value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
        console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
};

const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
};

const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
};

export default {
    addListeners,
    registerNotifications,
    getDeliveredNotifications,
};
*/
