import persist from './persist';
import sync from './synch';
import {fileExists} from './file';
import {getPlatforms} from '@ionic/vue';

const UPDATEDSTATES = {
    UNCHANGED: 0,
    UPDATED: 1,
};

const getUpdatedState = (state) => {
    const images = [];
    for (const i in state.images) {
        if (state.images[i].updatedState == UPDATEDSTATES.UPDATED) {
            images.unshift(state.images[i]);
        }
    }
    return images;
};

const getUpdatedVideoState = (state) => {
    const videos = [];
    for (const i in state.videos) {
        if (state.videos[i].updatedState == UPDATEDSTATES.UPDATED) {
            videos.unshift(state.videos[i]);
        }
    }
    return videos;
};

const getDefaultState = () => {
    return {
        images: {},
        videos: {},
        videoPath: '',
        videoUploadStatus: 0,
    };
};

const imageStore = {
    state() {
        return {
            images: {},
            videos: {},
            videoPath: '',
            videoUploadStatus: 0,
        };
    },
    mutations: {
        addVideo(state, {fileName}) {
            console.log('Adding video', fileName);
            const video = {
                fileName: fileName,
                updatedState: UPDATEDSTATES.UPDATED,
            };
            if (state.videos === undefined) {
                state.videos = {};
            }
            state.videos[fileName] = video;
            console.log('Added video', JSON.stringify(state.videos[fileName]));
        },
        addImage(state, {img, fileName}) {
            const image = {
                img: img,
                updatedState: UPDATEDSTATES.UPDATED,
                fileName: fileName,
            };
            state.images[fileName] = image;
        },
        async loadImage(state, {fileName}) {
            if (state.images[fileName] === undefined) {
                if (fileName === null || fileName === '' || fileName === undefined) {
                    return;
                }
                const img = await sync.getImage(fileName);
                const image = {
                    img: img,
                    updatedState: UPDATEDSTATES.UNCHANGED,
                    fileName: fileName,
                };
                state.images[fileName] = image;
                this.commit('persistImageState');
                return;
            } else {
                console.log('image already here');
            }
        },
        async loadVideo(state, {fileName}) { // hier wird das video runtergeladen
            if (state.videos[fileName] === undefined) {
                if (fileName === null || fileName === '' || fileName === undefined) {
                    return;
                }
                console.log('starting video download', fileName);
                const vid = await sync.getVideo(fileName);
                console.log('video downloaded', vid);
                const video = {
                    fileName: fileName,
                };
                console.log('Loading video', video);
                window.resolveLocalFileSystemURL(state.videoPath, (dir) => {
                    dir.getFile(fileName, {create: true}, (file) => {
                        file.createWriter((fileWriter) => {
                            fileWriter.write(videoData);
                        }, (error) => console.error('Unable to save video:', error));
                    });
                });
                // store the video on the device
                state.videos[fileName] = video;
                this.commit('persistImageState');
                return;
            } else {
                console.log('video already here');
            }
        },
        loadImagesState(state, store) {
            if (store !== null) {
                // console.log('Loading images state', JSON.stringify(store));
                state.images = store.images;
                state.videos = store.videos;
                state.videoPath = store.videoPath;
                if (state.videoPath === undefined) {
                    if (getPlatforms().includes('ios')) {
                        console.log('need to implement ios video path');
                    } else {
                        state.videoPath = '/storage/emulated/0/DCIM/Camera';
                    }
                }
            }
        },
        resetImages: (state) => {
            Object.assign(state, getDefaultState());
        },
        persistImageState: (state) => {
            persist.set('images', JSON.parse(JSON.stringify(state)));
        },
        resetImagesUpdatedState: (state) => {
            // eslint-disable-next-line guard-for-in
            for (const i in state.images) {
                state.images[i].updatedState = UPDATEDSTATES.UNCHANGED;
            }
        },
        resetVideosUpdatedState: (state) => {
            // eslint-disable-next-line guard-for-in
            for (const i in state.videos) {
                state.videos[i].updatedState = UPDATEDSTATES.UNCHANGED;
            }
        },
        setVideoPath: (state, {path}) => {
            state.videoPath = path;
        },
        setVideoUploadStatus(state, status) {
            console.log('Setting video upload status', status);
            state.videoUploadStatus = status;
        },
    },
    actions: {
        async addImage(context, {img, fileName}) {
            context.commit('addImage', {img, fileName});
            context.commit('persistImageState');
            this.dispatch('upsync');
        },
        async addVideo(context, {fileName}) {
            context.commit('addVideo', {fileName}); // --> Dateiname abspeichern
            // context.commit('persistImageState'); // --> auf handy speichern damit bei neustart der app noch da
            this.dispatch('upsync'); // --> video wird hochgeladen
        },
        setVideoPath(context, {path}) {
            context.commit('setVideoPath', {path});
        },
        async loadImage(context, {fileName}) {
            context.commit('loadImage', {fileName});
        },
        async loadVideo(context, {fileName}) {
            context.commit('loadVideo', {fileName});
        },
        async initImageStore({commit}) {
            const images = await persist.get('images');
            commit('loadImagesState', images);
        },
        videoUploaded(context) {
            console.log('ImageStore action Video uploaded');
            context.commit('setVideoUploadStatus', 1);
        },
        resetVideoUploaded(context) {
            console.log('ImageStore action Video uploaded');
            context.commit('setVideoUploadStatus', 0);
        },
        resetImages: (context) => {
            context.commit('resetImages');
        },
        resetImagesUpdatedState: ({commit}) => {
            commit('resetImagesUpdatedState');
        },
        // resetVideosUpdatedState: ({commit}) => {
        //     commit('resetImagesUpdatedState');
        // },
        resetVideosUpdatedState: ({commit}) => {
            commit('resetVideosUpdatedState');
        },
    },
    getters: {
        image: (state) => (fileName) => {
            if (state.images[fileName] !== undefined) {
                return state.images[fileName].img;
                // eslint-disable-next-line max-len
            } else if (fileName !== '' && fileName !== undefined && fileName !== null) {
                console.log('Image not here');
            }
        },
        video: (state) => async (fileName) => {
            if (fileName === undefined || fileName === null || fileName === '' || state.videoPath === undefined) {
                return;
            }
            const exists = await fileExists(state.videoPath, fileName);
            if (!exists) {
                console.log('Video not here', fileName);
                return;
            }
            if (state.videos[fileName] !== undefined) {
                console.log('Video is here', fileName);
                return `${state.videoPath}/${state.videos[fileName].fileName}`;
            } else {
                console.log('Video not in state', fileName);
                return;
            }
        },
        updatedImages(state) {
            return getUpdatedState(state);
        },
        updatedVideos(state) {
            return getUpdatedVideoState(state);
        },
        videoPath(state) {
            return state.videoPath;
        },
        videoUploadStatus(state) {
            console.log('Getting video upload status', state.videoUploadStatus);
            return state.videoUploadStatus;
        },
    },
};

export default imageStore;
