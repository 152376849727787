import axios from 'axios';
import {Buffer} from 'buffer/';
import store from '../services/store';


export default {
    async upload(image, filename) {
        const response = await fetch(image);
        const blob = await response.blob();
        const formData = new FormData();
        formData.append('file', blob, filename);
        return axios
            .post(process.env.VUE_APP_ROOT_API + 'sync/file/', formData)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return {status: 3, message: error};
            });
    },
    async download(filename) {
        const base64 = await axios
            .get(process.env.VUE_APP_ROOT_API + `sync/download_file/${filename}/`, {
                responseType: 'arraybuffer',
            })
            .then((response) =>
                Buffer.from(response.data, 'binary').toString('base64'),
            );
        const img = new Image();
        img.src = 'data:image/jpeg;base64, ' + base64;
        return img.src;
    },
    async uploadVideo(video, filename) {
        const response = await fetch(video);
        const blob = await response.blob();
        const formData = new FormData();
        formData.append('file', blob, filename);
        console.log('starting upload', formData);
        // Send a POST request to the API endpoint with the video file
        axios.post(process.env.VUE_APP_ROOT_API + 'sync/file/', formData, { // in formData steckt das video
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            store.dispatch('videoUploaded');
            return response.data;
        }).catch((error) => {
            console.log('Error uploading video', error);
            return {status: 3, message: error};
        });
    },
    // async downloadVideo(filename) {
    //     const base64 = await axios
    //         .get(process.env.VUE_APP_ROOT_API + `sync/download_file/${filename}/`, {
    //             responseType: 'arraybuffer',
    //         })
    //         .then((response) =>
    //             Buffer.from(response.data, 'binary').toString('base64'),
    //         );
    //     const videoSrc = 'data:video/mp4;base64,' + base64; // MOV hinzufügen?
    //     /*
    //     let videoSrc = '';
    //     if (filename.includes('.mp4')) {
    //         videoSrc = 'data:video/mp4;base64,' + base64;
    //     } else if (filename.includes('.mov')) {
    //         videoSrc = 'data:video/mov;base64,' + base64;
    //     }
    //     */
    //     return videoSrc;
    // },
};
