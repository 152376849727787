
const events = ({
    state: {
        globalEvent: null,
    },
    mutations: {
        setGlobalEvent(state, payload) {
            state.globalEvent = payload;
        },
    },
    actions: {
        updateGlobalEvent({commit}, payload) {
            commit('setGlobalEvent', payload);
        },
    },
});

export default events;
