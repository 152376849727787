<template>
    <ion-icon :icon="receiptOutline">

    </ion-icon>
</template>

<script>
import {IonIcon} from '@ionic/vue';
import {receiptOutline} from 'ionicons/icons';

export default {
    components: {
        IonIcon,
    },
    props: ['recipe'],
    data() {
        return {
            receiptOutline,
        };
    },
    computed: {
    },
};
</script>

<style scoped>
</style>
