import persist from './persist';

const UPDATEDSTATES = {
    UNCHANGED: 0,
    UPDATED: 1,
};
const CLOCK_START = 1;
const CLOCK_STOP = 2;

const getUpdatedState = (state) => {
    const time_data = {
        clockings: [],
        currentClock: state.currentClock,
        currentDate: state.currentDate,
    };
    if (state.updatedState == UPDATEDSTATES.UPDATED) {
        for (const s in state.clockings) {
            if (state.clockings[s].updatedState == UPDATEDSTATES.UPDATED) {
                time_data.clockings.unshift(state.clockings[s]);
            }
        }
    }
    return time_data;
};

const getDefaultState = () => {
    return {
        currentClock: CLOCK_STOP,
        clockings: [],
        updatedState: 0,
        currentDate: new Date(new Date().setHours(0, 0, 0, 0)),
    };
};

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

const timeStore = {
    state() {
        return {
            currentClock: CLOCK_STOP,
            clockings: [],
            updatedState: 0,
            currentDate: new Date(new Date().setHours(0, 0, 0, 0)),
        };
    },
    mutations: {
        timeUpdated(state) {
            state.updatedState = UPDATEDSTATES.UPDATED;
        },
        addLiveClocking(state, {vm, clockingData}) {
            if (state.currentClock == CLOCK_STOP) { // toggle clockType
                state.currentClock = CLOCK_START;
            } else if (state.currentClock == CLOCK_START) {
                state.currentClock = CLOCK_STOP;
            }
            const newClocking = {
                id: vm.$uuid(),
                createdTimestamp: vm.$timestamp(),
                editedTimestamp: vm.$timestamp(),
                clockTimestamp: clockingData.clockTimestamp,
                clockType: state.currentClock,
                updatedState: UPDATEDSTATES.UPDATED,
            };
            state.clockings.unshift(newClocking);
        },
        addPastClocking(state, {vm, clockingData}) {
            // eslint-disable-next-line max-len
            const start = new Date(new Date(clockingData.clockTimestamp).setHours(0, 0, 0, 0));
            const end = start.addDays(1);
            const timeclockings = state.clockings.filter((a) => {
                // eslint-disable-next-line max-len
                return (a.deleted != true && a.clockTimestamp >= start && a.clockTimestamp <= end);
            });
            let clockType = CLOCK_START;
            if (timeclockings.length % 2) {
                clockType = CLOCK_STOP;
            }
            const newClocking = {
                id: vm.$uuid(),
                createdTimestamp: vm.$timestamp(),
                editedTimestamp: vm.$timestamp(),
                clockTimestamp: clockingData.clockTimestamp,
                clockType: clockType,
                updatedState: UPDATEDSTATES.UPDATED,
            };
            state.clockings.unshift(newClocking);
        },
        updateClocking(state, {vm, clockingData}) {
            const clocking = state.clockings.find((c) => c.id === clockingData.id);
            clocking.clockTimestamp = clockingData.clockTimestamp;
            clocking.editedTimestamp = vm.$timestamp();
            clocking.updatedState = UPDATEDSTATES.UPDATED;
        },
        deleteClocking(state, {vm, clockingData}) {
            const clocking = state.clockings.find((c) => c.id === clockingData.id);
            clocking.editedTimestamp = vm.$timestamp();
            clocking.deleted = true;
            clocking.updatedState = UPDATEDSTATES.UPDATED;
        },
        reset: (state) => {
            Object.assign(state, getDefaultState());
        },
        loadTimeState(state, time) {
            state.clockings = time.clockings;
            state.currentClock = time.currentClock;
        },
        persistTimeState(state) {
            persist.set('time', JSON.parse(JSON.stringify(state)));
        },
        resetTimeUpdatedState(state) {
            // eslint-disable-next-line guard-for-in
            for (const s in state.clockings) {
                state.clockings[s].updatedState = UPDATEDSTATES.UNCHANGED;
            }
            state.updatedState = UPDATEDSTATES.UNCHANGED;
        },
        setCurrentDate(state, date) {
            state.currentDate = date;
        },
        setClockings(state, clockings) {
            state.clockings = clockings;
        },
    },
    actions: {
        addLiveClocking: ({dispatch, commit}, {vm, clockingData}) => {
            commit('addLiveClocking', {vm, clockingData});
            commit('persistTimeState');
            commit('timeUpdated');
            dispatch('upsync');
        },
        addPastClocking: ({dispatch, commit}, {vm, clockingData}) => {
            commit('addPastClocking', {vm, clockingData});
            commit('persistTimeState');
            commit('timeUpdated');
            dispatch('upsync');
        },
        updateClocking: ({dispatch, commit}, clockingData) => {
            commit('updateClocking', clockingData);
            commit('persistTimeState');
            commit('timeUpdated');
            dispatch('upsync');
        },
        deleteClocking: ({dispatch, commit}, clockingData) => {
            commit('deleteClocking', clockingData);
            commit('persistTimeState');
            commit('timeUpdated');
            dispatch('upsync');
        },
        reset: (context) => {
            context.commit('resetTime');
        },
        async initTimeStore(context) {
            const time = await persist.get('time');
            if (time !== null) {
                // eslint-disable-next-line guard-for-in
                for (const t in time.clockings) {
                    // eslint-disable-next-line max-len
                    time.clockings[t].createdTimestamp = new Date(time.clockings[t].createdTimestamp);
                    // eslint-disable-next-line max-len
                    time.clockings[t].editedTimestamp = new Date(time.clockings[t].editedTimestamp);
                    // eslint-disable-next-line max-len
                    time.clockings[t].clockTimestamp = new Date(time.clockings[t].clockTimestamp);
                }
                context.commit('loadTimeState', time);
            }
        },
        resetTimeUpdatedState({commit}) {
            commit('resetTimeUpdatedState');
        },
        setCurrentDate({dispatch, commit}, date) {
            commit('setCurrentDate', date);
            dispatch('downsync');
        },
        setTimeState({commit}, data) {
            if (data !== undefined) {
                // eslint-disable-next-line guard-for-in
                for (const t in data.clockings) {
                    // eslint-disable-next-line max-len
                    data.clockings[t].createdTimestamp = new Date(data.clockings[t].createdTimestamp);
                    // eslint-disable-next-line max-len
                    data.clockings[t].editedTimestamp = new Date(data.clockings[t].editedTimestamp);
                    // eslint-disable-next-line max-len
                    data.clockings[t].clockTimestamp = new Date(data.clockings[t].clockTimestamp);
                }
                commit('setClockings', data.clockings);
            }
        },
    },
    getters: {
        currentClock(state) {
            return state.currentClock;
        },
        timeclockings: (state) => (timestamp) =>{
            const end = timestamp.addDays(1);
            const timeclockings = state.clockings.filter((a) => {
                // eslint-disable-next-line max-len
                return (a.deleted != true && a.clockTimestamp >= timestamp && a.clockTimestamp <= end);
            });
            return timeclockings;
        },
        lastStartTimestamp(state) {
            // eslint-disable-next-line max-len
            const clock = state.clockings.find((clock) => clock.clockType === CLOCK_START);
            if (clock !== undefined) {
                return clock.clockTimestamp;
            } else {
                return undefined;
            }
        },
        clocking: (state) => (clocking_id) => {
            return state.clockings.find((clock) => clock.id === clocking_id);
        },
        updatedTime(state) {
            return getUpdatedState(state);
        },
        currentTime(state) {
            return state;
        },
    },
};

export default timeStore;
