<template>
    <ion-page id="main-content">
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-menu-button v-if="isHome"></ion-menu-button>
                    <div v-else>
                        <ion-back-button :default-href="pageDefaultBackLink" :text="''"></ion-back-button>
                    </div>
                </ion-buttons>
                <ion-title>
                    {{ pageTitle }}
                </ion-title>
                <ion-buttons slot="end">
                    <slot name="actions-end">
                        <cloud-status />
                    </slot>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content ref="content" :scroll-events="true" @ionScroll="handleScroll">
            <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <slot />
        </ion-content>
        <save-button v-if="hasSave" @save="save" />
        <scroll-button v-if="showScrollButton" @click="scrollToTop" />
    </ion-page>
</template>

<script>
import {IonPage, IonHeader, IonTitle, IonContent, IonToolbar, IonBackButton, IonButtons, IonMenuButton, IonRefresher, IonRefresherContent} from '@ionic/vue';
import {homeSharp, arrowUpOutline} from 'ionicons/icons';
import CloudStatus from './CloudStatus.vue';
import SaveButton from './SaveButton.vue';
import ScrollButton from './ScrollButton.vue';

export default {
    props: ['pageTitle', 'pageDefaultBackLink', 'isHome', 'hasSave'],
    data() {
        return {
            homeSharp,
            arrowUpOutline,
            showScrollButton: false,
        };
    },
    components: {
        CloudStatus,
        SaveButton,
        IonPage,
        IonHeader,
        IonTitle,
        IonContent,
        IonToolbar,
        IonBackButton,
        IonButtons,
        IonMenuButton,
        IonRefresher,
        IonRefresherContent,
        ScrollButton,
    },
    computed: {
        usageAllowed() {
            return this.$store.getters.usageAllowed;
        },
    },
    mounted() {
        console.log('BaseLayout loading default avatar image...');
        this.$store.dispatch('loadImage', {fileName: 'avatar.png'});
    },
    methods: {
        save() {
            this.$emit('save');
        },
        goHome() {
            this.$router.push('/');
        },
        handleRefresh(event) {
            this.$store.dispatch('downsync');
            this.$syncCallbacks.executeCallbacks();
            setTimeout(() => {
                // Any calls to load data go here
                event.target.complete();
            }, 1000);
        },
        handleScroll() {
            const scrollTop = event.detail.scrollTop;
            // console.log('scrolled', scrollTop);
            if (scrollTop > window.innerHeight * 2) {
                this.showScrollButton = true;
            } else {
                this.showScrollButton = false;
            }
        },
        scrollToTop() {
            this.$refs.content.$el.scrollToTop(500);
        },
    },
};
</script>
