import persist from './persist';


const getDefaultState = () => {
    return {
        colleagues: [],
    };
};

const colleagueStore = {
    state() {
        return {
            colleagues: [],
        };
    },
    mutations: {
        resetColleagues: (state) => {
            Object.assign(state, getDefaultState());
        },
        persistColleagueState: (state) => {
            persist.set('colleague', JSON.parse(JSON.stringify(state)));
        },
        setColleagues: (state, colleagues) => {
            if (colleagues !== null) {
                state.colleagues = colleagues.colleagues;
            }
        },
    },
    actions: {
        async initColleagueStore({commit}) {
            const colleagues = await persist.get('colleague');
            commit('setColleagues', colleagues);
        },
        setColleagueState({commit}, colleagues) {
            commit('setColleagues', colleagues);
        },
    },
    getters: {
        colleagues(state) {
            return state.colleagues;
        },
        colleagueById: (state) => (user_id) => {
            return state.colleagues.find((user) => user.user_id === user_id);
        },
    },
};

export default colleagueStore;
