<template>
    <dashboard-card :local_image="'ownerdash.jpg'">
        <synced-component />
        <ion-text>
            <h2>Heute {{ currentDate }}:</h2>
        </ion-text>
        <ion-list>
            <ion-item>
                <ion-label>
                    <h1>Therapeuten:</h1>
                </ion-label>
                <ion-label slot="end">
                    <h1>{{ owner_dashboard.count_therapists }}</h1>
                </ion-label>
            </ion-item>
            <ion-item>
                <ion-label>
                    <h1>Termine:</h1>
                </ion-label>
                <ion-label slot="end">
                    <h1>{{ owner_dashboard.count_appointments }}</h1>
                </ion-label>
            </ion-item>
            <ion-item @click="() => $router.push('/invoices')">
                <ion-label>
                    <h1>Offene Rechnungen:</h1>
                </ion-label>
                <ion-label slot="end">
                    <h1>{{ owner_dashboard.count_open_invoices }}</h1>
                </ion-label>
            </ion-item>
        </ion-list>
    </dashboard-card>
</template>

<script>
import DashboardCard from './DashboardCard.vue';
import {IonLabel, IonItem, IonText, IonList} from '@ionic/vue';
import SyncedComponent from '@/components/base/SyncedComponent.vue';

export default {
    components: {
        DashboardCard,
        IonLabel,
        IonItem,
        IonText,
        IonList,
        SyncedComponent,
    },
    name: 'OwnerDashboard',
    provide() {
        return {
            load: this.load,
        };
    },
    data() {
        return {
            owner_dashboard: [],
            currentDate: '',
        };
    },
    methods: {
        async load() {
            this.$dataGetter(`ownerdashboard/`, (data) => {
                this.owner_dashboard = data;
            });
        },
    },
    computed: {

    },
    mounted() {
        const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'};
        const currentDate = new Date().toLocaleDateString('de-DE', dateOptions);
        this.currentDate = currentDate;
    },
};
</script>
